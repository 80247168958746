import React from 'react';
import Layout, { linkStyle } from '../components/layout';
import { Seo } from "../components/seo"
import og_img from '../images/166A3394_og.jpg';
import {StaticImage} from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import OutboundLink from '../components/outboundlink';

function activateBioDetails () {
  if(document) {
    let openDetails = document.getElementById('openDetails')
    openDetails.classList.add('hidden');
    let bioDetails = document.getElementById('bioDetails')
    bioDetails.classList.remove('hidden');
  }
}

const AboutPage = () => {
  return (
    <Layout pagePath="/about">
      <div className="container flex flex-wrap mx-auto px-4 sm:px-0">
        <div className="w-2/3 sm:w-1/3 m-auto sm:m-0 px-3">
          <StaticImage className="max-w-sm" src="../images/166A3394.jpg" alt="Ryan sits on his former front porch near Colorado Springs, Colorado"/>
        </div>
        <div className="w-full sm:w-2/3 px-8">
          <h1 className="py-4 text-4xl font-medium text-center">About Ryan</h1>
          <p className='text-center mb-4'>
            <Link to="/resume/" className={linkStyle + ' mx-4'}>Static Résumé</Link>
            <Link to="/interactiveresume/" className={linkStyle + 'mx-4'}>Interactive Résumé</Link>
          </p>
          <p className="mb-4 text-justify">
            Hi, I'm Ryan, a family oriented guy with an amazing wife and three wonderful kids.
            When I'm not spending time with my family, I'm typically doing work for
            <OutboundLink href="http://www.marqeta.com" target="_blank" rel="noreferrer" className={linkStyle}> Marqeta</OutboundLink>,
            working on the <Link to="/sourcesedge" className={linkStyle}>Source's Edge</Link> series, coding for my
            own projects, reading, tinkering, drumming, or home renovating
            with my talented wife, <OutboundLink href="https://www.instagram.com/alysonsivek/" target="_blank" rel="noreferrer" className={linkStyle}>Alyson</OutboundLink>.
          </p>
          <button className={linkStyle + " mb-4 cursor-pointer"} id="openDetails" onClick={activateBioDetails}>
            Read more...
          </button>
          <div id="bioDetails" className="hidden text-justify">
            <p className="mb-4">
              I always struggle to identify my "hometown", since my family moved around a lot
              when I was young. We lived in Utah, New Mexico, Texas,
              and eventually moved to Florida, where I attended high school. I developed
              interests in many different areas, including science, music, and particularly
              television production and video editing. I co-produced the school news during my
              senior year at <OutboundLink href="https://www.manateeschools.net/lakewoodranch" target="_blank" rel="noreferrer" className={linkStyle}>Lakewood Ranch
              High School</OutboundLink>.
            </p>
            <p className="mb-4">
              After high school, I served a mission for <OutboundLink href="http://www.churchofjesuschrist.org" target="_blank" rel="noreferrer" className={linkStyle}>
              The Church of Jesus Christ of Latter Day Saints</OutboundLink>, where I was assigned to the
              beautiful islands of Fiji. I consider the two years of my service there as some of
              the most influential in my life.
            </p>
            <p className="mb-4">
              Upon returning from my mission to Fiji, I enrolled at the <OutboundLink href="https://www.ucf.edu/" target="_blank" rel="noreferrer" className={linkStyle}>
              University of Central Florida</OutboundLink>, initially aiming to enter the Film program.
              Over the summer, a friend introduced me to Alyson,
              who had returned home from her first year at <OutboundLink href="https://www.byu.edu/" target="_blank" rel="noreferrer" className={linkStyle}>Brigham Young University</OutboundLink>.
              As our relationship evolved from friendship to romance, I started to think hard about
              what impacts my career aspirations might have on raising a family, and ultimately
              decided to switch my aims from Film to Computer Engineering.
            </p>
            <p className="mb-4">
              The engineering program absolutely <i>fascinated</i> me! I fell in love with the mixed
              focus on Computer Science and Electrical Engineering, and discovered a knack for writing code.
              Alyson and I were married during my Sophomore year. In 2012 I was accepted into the MITRE co-op
              program and was later hired on part time while still engaged in my undergraduate courses.
              After graduating with a BS in Computer Engineering (and a minor in Psychology), I
              was hired on full-time at <OutboundLink href="https://www.mitre.org/" target="_blank" rel="noreferrer" className={linkStyle}>The MITRE Corporation</OutboundLink>.
            </p>
            <p className="mb-4">
              During my undergraduate program, I had the privilege of taking a fascinating Biomedical Nanotechnology course,
              and found myself instantly drawn to the junction between computers, biology, and health. I
              applied to the Master's program in <OutboundLink href="https://engineering.virginia.edu/departments/biomedical-engineering" target="_blank" rel="noreferrer" className={linkStyle}>
              Biomedical Engineering</OutboundLink> at <OutboundLink href="https://www.virginia.edu/" target="_blank" rel="noreferrer" className={linkStyle}>the University
              of Virginia</OutboundLink>, and Alyson and I relocated to Charlottesville. 
              My son was born just before my first semester began in the Fall of 2015. My
              daughter was born two years later in 2017, while I was still working through my Master's program.
            </p>
            <p className="mb-4">
              After receiving my Master's degree, my wife and I had a desire to move out west to
              get cozy with the mountains. We relocated to the Colorado Springs, Colorado area in 2019,
              where we lived for only a short time before COVID-19 spread across the globe.
              I began working on the first novel of the <Link to="/sourcesedge" className={linkStyle}>Source's Edge</Link> series,
              a narrative which has been evolving in my mind since I was in my early teenage years.
            </p>
            <p className="mb-4">
              In the fall of 2020, I took a fully remote job with <OutboundLink href="http://www.marqeta.com" target="_blank" rel="noreferrer" className={linkStyle}>Marqeta</OutboundLink>,
              a company out of the California Bay Area doing groundbreaking work in the financial technology industry.
              I'm currently an Engineering Manager on the Data &amp; Insights team, where I manage a group
              of engineers and lead engineering work on Marqeta's <OutboundLink href="https://www.marqeta.com/docs/diva-api/introduction" target="_blank" rel="noreferrer" className={linkStyle}>reporting infrastructure</OutboundLink>.
            </p>
            <p className="mb-4">
              In 2021, we decided to move back out east to Raleigh, North Carolina to be closer to family.
              Soon thereafter, I started the process of preparing my first novel, <Link to="/sourcesedge/thesecretsoftercast" className={linkStyle}>The Secrets of Tercast</Link>,
              for publication, and it was released in the fall of 2022!
            </p>
            <p className="mb-4">
              One year later, in the fall of 2023, we welcomed our third child to the world while I was editing an
              early draft of book two. We love our little girl!
            </p>
          </div>
          <div className="py-4">
            <p className="my-4">Have a question or comment? Please feel free to <Link to="/contact" className={linkStyle}>contact me</Link>!</p>
          </div>
        </div>
      </div>
    </Layout>
  )
};

export default AboutPage;

export const Head = () => (
  <Seo pathname={"/about"} image={og_img}/>
)
